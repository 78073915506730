.learning-options-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.learning-option-button {
  padding: 0.5rem;
  border-radius: 25px;
  background: transparent;
  box-shadow:  2px 2px 0px rgb(0 0 0 / 24%);
  border: 1px solid #e0e0e0;
  margin: 3px;
}
.link-list {
  padding: 0;
}

.link-list-item {
  text-align: left;
  font-size: 0.9rem;
}

.link-list-item-url {
  text-decoration: none;
  margin: 6px;
  display: block;
  color: #1d1d1d;
  background-color: #f1f1f1;
  padding: 8px;
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgba(150, 149, 149, 0.4);
}
.form-consulta {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 10px;
  width: 85%;
  height: 100%;
}

.review-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.leccion-link {
  text-decoration: none;
  padding: 8px;
  border-radius: 3px;
  background-color: white;
  color: black;
  box-shadow: 2px 2px 4px rgba(150, 149, 149, 0.4);
}

.review-buttons button {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  width: 100%;
  height: 100%;
}

/* animations */

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1.5);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.5);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}


/* Alternative animation for loader */

.loading {
  left: 50%;
  top: 50%;
  width: 85%;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 3.4px;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -60%);
}

.loading::before {
  color: #878fac;
  content: attr(data-loading-text);
}

.loading::after {
  top: 0;
  left: 0;
  width: 0;
  opacity: 1;
  color: white;
  overflow: hidden;
  position: absolute;
  content: attr(data-loading-text);
  animation: loading 5s infinite;
}

@keyframes loading {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
.learning-options-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.learning-option-button {
  padding: 0.5rem;
  border-radius: 25px;
  background: transparent;
  box-shadow:  2px 2px 0px rgb(0 0 0 / 24%);
  border: 1px solid #e0e0e0;
  margin: 3px;
}
.learning-options-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.learning-option-button {
  padding: 0.5rem;
  border-radius: 25px;
  background: transparent;
  box-shadow:  2px 2px 0px rgb(0 0 0 / 24%);
  border: 1px solid #e0e0e0;
  margin: 3px;
}
.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 1299;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
}

.react-chatbot-kit-chat-container {
  width: 340px;
}

.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 25px;
  background-color: transparent;
  border: none;
  position: fixed;
  bottom: 15px;
  z-index: 1299;
  right: 30px;
}

.app-chatbot-button-icon {
  width: 60px;
  height: 60px;
  fill: #fff;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #878fac;
  width: 100%;
  margin-left: 0;
}

.react-chatbot-kit-chat-bot-avatar {
  display: none;
}

.react-chatbot-kit-user-chat-message {
  inline-size: auto;
  overflow-wrap: break-word;
  display: flex;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar {
  display: none;
}

.chatmodal-messages-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 50vh;
    max-height: 50vh;
}

.chatmodal-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
    text-align: left;
    color: red;
    font-weight: bold;
}

.chatmodal-message .chatmodal-response {
    border-radius: 10px;
    background-color: #e5e5ea;
    min-width: 80%;
    max-width: 80%;
    overflow-x: scroll;
    padding: 0.5rem 1rem;
}

.chatmodal-message-loading {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 1.5rem;
}

.chatmodal-messages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
}

.chatmodal-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
  text-align: left;
  color: red;
  font-weight: bold;
}

.chatmodal-message .chatmodal-response {
  border-radius: 10px;
  background-color: #e5e5ea;
  min-width: 80%;
  max-width: 80%;
  overflow-x: scroll;
  padding: 0.5rem 1rem;
}

.chatmodal-message-loading {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1.5rem;
}
.chatmodal-container {
  overflow: clip;
}

.chatmodal-messages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
}

.chatmodal-title {
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  justify-content: space-between;
  padding: 0rem 0rem 0rem 0rem;
}

.chatmodal-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: small;
  margin-bottom: 1rem;
}

.chatmodal-message .chatmodal-message-role {
  text-align: left;
  color: red;
  font-weight: bold;
}

.chatmodal-message .chatmodal-response {
  border-radius: 10px;
  background-color: #e5e5ea;
  padding: 0.5rem 1rem;
}

.chatmodal-message-input {
  display: flex;
  flex-direction: col;
  align-items: center;
  justify-content: between;
  margin: 0.5rem 1.5rem;
}

.chatmodal-message-loading {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chatmodal-message-loading-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  margin-top: 2rem;
  animation: dotElastic 1s infinite ease-in-out;
}

.chatmodal-engine-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0rem;
}

.chatmodal-engine-feedback-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.chatmodal-tab-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0rem;
}

.chatmodal-tabs {
  text-transform: none;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.chatmodal-tabs-tab {
  font-size: 0.8rem;
  font-weight: bold;
  width: 100%;
  height: 100%;
  overflow: visible;
}
.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-title {
  font-size: medium;
  font-weight: bold;
}

.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.chatmodal-engine-feedback-dialog .chatmodal-engine-feedback-dialog-buttons button {
  width: 15%;
  height: 1.5rem;
}
