.learning-options-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.learning-option-button {
  padding: 0.5rem;
  border-radius: 25px;
  background: transparent;
  box-shadow:  2px 2px 0px rgb(0 0 0 / 24%);
  border: 1px solid #e0e0e0;
  margin: 3px;
}