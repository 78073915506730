.form-consulta {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 10px;
  width: 85%;
  height: 100%;
}

.review-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.leccion-link {
  text-decoration: none;
  padding: 8px;
  border-radius: 3px;
  background-color: white;
  color: black;
  box-shadow: 2px 2px 4px rgba(150, 149, 149, 0.4);
}

.review-buttons button {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  width: 100%;
  height: 100%;
}

/* animations */

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1.5);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.5);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.67);
  }

  75% {
    transform: scale(1, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}


/* Alternative animation for loader */

.loading {
  left: 50%;
  top: 50%;
  width: 85%;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 3.4px;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -60%);
}

.loading::before {
  color: #878fac;
  content: attr(data-loading-text);
}

.loading::after {
  top: 0;
  left: 0;
  width: 0;
  opacity: 1;
  color: white;
  overflow: hidden;
  position: absolute;
  content: attr(data-loading-text);
  animation: loading 5s infinite;
}

@keyframes loading {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}